@import "variables";
@import "../skeleton/bootstrap";

$base-font-size: 16px;

// The base line height determines the basic unit of vertical rhythm.
$base-line-height: 40px;

// The length unit in which to output vertical rhythm values.
// Supported values: px, em, rem.
$base-unit: 'rem';

// The default font family.
$base-font-family: $font-family--default;

// The font sizes for h1-h6.
$h1-font-size: 38px;
$h2-font-size: 30px;
$h3-font-size: 25px;
$h4-font-size: 1    * $base-font-size;
$h5-font-size: 0.83 * $base-font-size;
$h6-font-size: 0.67 * $base-font-size;

// The amount lists and blockquotes are indented.
$indent-amount: 50px;

// The following variable controls whether normalize-scss will output
// font-sizes, line-heights and block-level top/bottom margins that form a basic
// vertical rhythm on the page, which differs from the original Normalize.css.
// However, changing any of the variables above will cause
// $normalize-vertical-rhythm to be automatically set to true.
$normalize-vertical-rhythm: false;

@import "~normalize-scss/sass/normalize/import-now";

#myopus-eu {
  width: 100%;
  line-height: 1.4;
  font-family: $base-font-family !important;
  font-weight: 400;
  color: var(--color--default);

  @include media-breakpoint-up(md) {
    font-size: $base-font-size !important;
    line-height: $base-line-height !important;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box; // 1
  }

  img, picture {
    max-width: 100%;
  }


  hr {
    border: 1px solid var(--color--hr);
    margin: 0 0 $spacing--default 0;
  }
}

