$font-family--default: 'Source Sans Pro', sans-serif;


// Font size
$rem-baseline: 25px;
//$font-size--xxxl: 3.125rem; //50px;
//$font-size--xxl: 2.125rem; //34px;
$font-size--xl: 46px;
$font-size--l: 38px;
$font-size--m: 30px;
$font-size--default: 25px;
$font-size--s: 23px;
$font-size--xs: 17px;


//
//// Line height
//$line-height--l: 1.7;
$line-height--default: 40/25;
//$line-height--s: 1.2;

// Colors
$color--primary: #2151A8;
$color--primary--light: rgba($color--primary, .19);
$color--secondary: #E9EFF3;
$color--tertiary: #EBDA40;
$color--quaternary: rgba(#F4F8FA, .81);
$color--1: #49E907;
$color--2: #AAB0BB;
$color--3: #73A1C2;
$color--default: $color--primary;


$color--white: #fff;
$color--dark: #1E1E1E;


$zIndexes: (
        'base': 1,
);


$form-input-background-color: #fff;
$form-input-border-color: #73A1C2;
$form-input-color: #73A1C2;

$spacing--default: 32px;


